import {I18n} from '@lingui/core';
import {ComparedPaymentVolumeByDateOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  LocaleCode,
  VolumeComparisonChartMode,
  formatAmount,
  formatPercentageDifference,
} from '@zentact/common';
import {ApexOptions} from 'apexcharts';
import {DateTime} from 'luxon';
import {getPalleteFromPrimaryColor} from '../../theme';

type InitChartDataType = {
  data?: ComparedPaymentVolumeByDateOutput;
  primaryColor: string;
  currency: CurrencyCode;
  locale: LocaleCode;
  i18n: I18n;
  mode: VolumeComparisonChartMode;
};

export const initVolumeComparisonChartData = ({
  data,
  primaryColor,
  currency,
  locale,
  mode,
  i18n,
}: InitChartDataType) => {
  // the bar is not showing for the zeros so we need to pass some small valu to the chart but display a real value in the tooltip
  const minHeightForZeroValue = 0.01;

  const seriesData = [
    data?.currentPeriod.map(({date, volume}) => ({
      date,
      value: volume,
      chartValue: volume || minHeightForZeroValue,
    })) || [],
    data?.previousPeriod.map(({date, volume}) => ({
      date,
      value: volume,
      chartValue: volume || minHeightForZeroValue,
    })) || [],
  ];

  const {currentPeriodTitle, previousPeriodTitle, formatLabelDate, tooltipDateFormat} =
    getModeSettings(mode, i18n);

  const dataX = data?.currentPeriod.map(({date}) => formatLabelDate(date)) || [];
  const pallete = getPalleteFromPrimaryColor(primaryColor);
  const seriesColors = [pallete[300], pallete[500]];
  const seriesColorsReversed = [pallete[500], pallete[300]];

  return {
    type: 'bar',
    height: 380,
    series: [
      {
        name: previousPeriodTitle,
        data: seriesData[1]?.map(v => v.chartValue) ?? [],
      },
      {
        name: currentPeriodTitle,
        data: seriesData[0]?.map(v => v.chartValue) ?? [],
      },
    ] satisfies ApexOptions['series'],
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        custom: (options: {dataPointIndex: number; series: number[][]}) => {
          const oldValue = seriesData[0]?.[options.dataPointIndex]?.value ?? 0;
          const newValue = seriesData[1]?.[options.dataPointIndex]?.value ?? 0;
          const percentage =
            (newValue && oldValue && formatPercentageDifference(oldValue, newValue, locale)) || '';
          const isPositive = newValue > oldValue && percentage;
          return `
          <div class="flex p-2 rounded-md gap-y-2 flex-col">
            <div class="flex items-center justify-between">
              <span class="font-semibold">${dataX[options.dataPointIndex]}</span>
              <span class="${isPositive ? 'text-green-500' : 'text-red-500'}">${
                isPositive ? '+' : ''
              }${percentage}</span>
            </div>
            ${options.series
              .map((_series, i) => {
                const value = seriesData[i]?.[options.dataPointIndex]?.value ?? 0;
                const date = seriesData[i]?.[options.dataPointIndex]?.date ?? '';
                return `
                <div class="flex items-center">
                  <div class="flex items-center">
                    <span class="mr-2" style="height: 14px; width: 14px;" data:collapsed="false">
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%">
                        <path d="M 0, 0 
                          m -6, 0 
                          a 6,6 0 1,0 12,0 
                          a 6,6 0 1,0 -12,0" fill="${seriesColorsReversed[i]}" fill-opacity="1" stroke="#ffffff" stroke-opacity="0.9" stroke-linecap="square" stroke-width="1" stroke-dasharray="0" cx="0" cy="0" shape="circle" class="apexcharts-legend-marker apexcharts-marker apexcharts-marker-circle" style="transform: translate(50%, 50%);">
                        </path>
                      </svg>
                    </span>
                  </div>
                  <span class="mr-5 w-full">${DateTime.fromISO(date).toFormat(tooltipDateFormat)}</span>
                  <span>${formatAmount(value, locale, currency, 0)}</span>
                </div>
              `;
              })
              .join('\n')}
          </div>`;
        },
      },
      colors: seriesColors,
      dataLabels: {
        enabled: false,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          shape: 'circle',
          size: 6,
          offsetY: -2,
          offsetX: -4,
        },
        itemMargin: {horizontal: 16},
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: dataX,
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      grid: {
        show: true,
        borderColor: '#e2e8f0',
        strokeDashArray: 3,
        padding: {
          right: 24,
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: v => {
            // when the chart has small/zero values on X axis the lib can try to pass decimal values to Y axis.
            // math round is here to prevent this behaviour
            const value = Math.round(v);
            return formatAmount(value, locale, currency, 0);
          },
        },
      },
    } satisfies ApexOptions,
  } as const;
};

const getModeSettings = (mode: VolumeComparisonChartMode, i18n: I18n) => {
  switch (mode) {
    case VolumeComparisonChartMode.YEAR_BY_MONTHS:
      return {
        currentPeriodTitle: i18n._('Current Year'),
        previousPeriodTitle: i18n._('Previous Year'),
        formatLabelDate: (dateISO: string) => DateTime.fromISO(dateISO).toFormat('MMM'),
        tooltipDateFormat: 'MMM yyyy',
      };
    case VolumeComparisonChartMode.MONTH_BY_DAYS:
      return {
        currentPeriodTitle: i18n._('Current 30 days'),
        previousPeriodTitle: i18n._('Previous 30 days'),
        formatLabelDate: (dateISO: string) => DateTime.fromISO(dateISO).toFormat('MMM dd'),
        tooltipDateFormat: 'MMM dd',
      };
    case VolumeComparisonChartMode.WEEK_BY_DAYS:
      return {
        currentPeriodTitle: i18n._('Current 7 days'),
        previousPeriodTitle: i18n._('Previous 7 days'),
        formatLabelDate: (dateISO: string) => DateTime.fromISO(dateISO).toFormat('EEE'),
        tooltipDateFormat: 'MMM dd',
      };
  }
};
