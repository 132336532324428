import {Trans} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {TerminalsItem, TerminalsOutput} from '@zentact/api/src/trpc/routers/terminalRouter';
import {TableColumnSize} from '@zentact/common';
import {FlatPillWithDot} from '@zentact/ui-tailwind';
import {ManageActions} from './manage-actions';

const columnsHelper = createColumnHelper<TerminalsOutput['rows'][0]>();

type PillProps = React.ComponentProps<typeof FlatPillWithDot>;
const orgStatusToColor: Record<string, PillProps['color']> = {
  boarded: 'green',
  inventory: 'yellow',
};

type Props = {
  refetchTerminalsList: () => void;
  openViewTerminalPanel: (row: TerminalsItem | null) => void;
};

export const getTerminalsManageColumns = ({refetchTerminalsList, openViewTerminalPanel}: Props) => [
  columnsHelper.display({
    cell: props => props.row.original.organizationName,
    header: () => <Trans>Organization</Trans>,
    id: 'organizationName',
  }),
  columnsHelper.display({
    cell: props => props.row.original.businessName,
    header: () => <Trans>Merchant Account</Trans>,
    id: 'businessName',
    meta: {
      collapseAt: 'sm',
    },
  }),
  columnsHelper.display({
    cell: props => props.row.original.storeName,
    header: () => <Trans>Store</Trans>,
    id: 'storeName',
    meta: {
      collapseAt: 'sm',
    },
  }),
  columnsHelper.display({
    id: 'id',
    cell: props => props.row.original.id,
    header: () => <Trans>ID</Trans>,
    size: TableColumnSize.S,
  }),
  columnsHelper.display({
    id: 'status',
    cell: ({row: {original}}) => {
      return (
        <>
          {!!original.status && (
            <FlatPillWithDot
              label={original.status
                .replace(/([A-Z])/g, ' $1')
                .replace(/\b\w/g, char => char.toUpperCase())
                .trim()}
              color={orgStatusToColor[original.status] || 'blue'}
            />
          )}
        </>
      );
    },
    header: () => <Trans>Status</Trans>,
    meta: {
      collapseAt: 'md',
    },
    size: TableColumnSize.XS,
  }),
  columnsHelper.display({
    id: 'actions',
    cell: props => (
      <div className="flex justify-center">
        <ManageActions
          refetchTerminalsList={refetchTerminalsList}
          terminalsRow={props.row.original}
          openViewTerminalPanel={openViewTerminalPanel}
        />
      </div>
    ),
    size: TableColumnSize.ACTIONS,
  }),
];
