import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import {ServerRouter} from '@zentact/api';
import {CurrencyCode, LocaleCode, VolumeComparisonChartMode} from '@zentact/common';
import ReactApexChart from 'react-apexcharts';
import {Loading} from '../../other';
import {Typography} from '../typography';
import {initVolumeComparisonChartData} from './init-volume-comparison-chart-data';

export type VolumeComparisonChartProps = {
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  currency: CurrencyCode;
  timezone: string;
  merchantAccountId?: string;
  organizationId?: string;
  pspMerchantAccountName?: string;
  storeId?: string;
  endDate: Date;
  mode: VolumeComparisonChartMode;
  primaryColor: string;
  locale: LocaleCode;
};

export const VolumeComparisonChart = ({
  trpc,
  merchantAccountId,
  organizationId,
  storeId,
  pspMerchantAccountName,
  timezone,
  currency,
  endDate,
  primaryColor,
  mode,
  locale,
}: VolumeComparisonChartProps) => {
  const {i18n} = useLingui();

  const {data, isLoading} = trpc.payment.getComparedPaymentVolumeByDate.useQuery({
    pspMerchantAccountName,
    merchantAccountId,
    organizationId,
    storeId,
    timezone,
    endDate,
    mode,
  });
  const chartData = initVolumeComparisonChartData({
    i18n,
    mode,
    data,
    primaryColor,
    currency,
    locale,
  });
  const emptyChart =
    data && !data.currentPeriod.find(v => v.volume) && !data.previousPeriod.find(v => v.volume);

  return (
    <div className="mt-5 overflow-hidden bg-white rounded-lg shadow">
      <div className="py-5 pl-4 pr-2 sm:pl-6 sm:pr-2">
        <Typography className="mb-5" variant="header-section">
          {i18n._('Volume of Transactions')}
        </Typography>
        {isLoading ? (
          <Loading className="w-full h-96" />
        ) : emptyChart ? (
          <div className="flex items-center justify-center w-full text-sm text-gray-400 h-96">
            {i18n._('No Data')}
          </div>
        ) : (
          <ReactApexChart {...chartData} key={currency} />
        )}
      </div>
    </div>
  );
};
