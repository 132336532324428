import {RoutePath} from '@/components/layout/navigation';
import {Trans, t} from '@lingui/macro';
import {SplitConfigurationGroupListItemOutput} from '@zentact/api/src/trpc/routers/splitConfigurationGroupRouter';
import {allIndustryGroups, capitalize, isValidPhoneNumber} from '@zentact/common';
import {
  AddressPrediction,
  InputText,
  Label,
  MultiEntityPicker,
  ValidationError,
  useAddressAutocomplete,
} from '@zentact/ui-tailwind';
import {InputPhone, InputSearchSelect, InputSelect, Typeahead} from '@zentact/ui-tailwind/forms';
import {useCallback, useEffect, useState} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import z from 'zod';

const addressRegex = /^[\p{L}a-zA-Z0-9\s,.'-]*$/u;

export const getAddStoreFormSchema = () =>
  z.object({
    displayName: z
      .string()
      .min(1, t`Store display name is required`)
      .max(80, t`Store display name cannot be longer than 80 characters`),
    storeReferenceId: z
      .string()
      .refine(value => /^[a-zA-Z0-9_-]*$/.test(value), {
        message: t`Store reference can contain only letters, numbers and "-" or "_" characters`,
      })
      .optional(),
    businessAddress: z.object({
      line1: z.string().min(1, t`Line 1 is required`).regex(addressRegex, t`Invalid Address`),
      line2: z.string().regex(addressRegex, t`Invalid Address`).optional(),
      city: z.string().min(1, t`City is required`).regex(addressRegex, t`Invalid City`),
      state: z.union([
        z
          .string()
          .min(2, t`State or Province is required`)
          .max(3, t`Please use two or three letter abbreviation for the state`)
          .regex(addressRegex, t`Invalid State`),
        z.string().length(0),
      ]),
      postalCode: z
        .string()
        .min(1, t`Postal code is required`)
        .regex(addressRegex, t`Invalid Postal Code`),
    }),
    businessPhoneNumber: z
      .string({
        // biome-ignore lint/style/useNamingConvention: <explanation>
        invalid_type_error: t`Phone number is invalid`,
      })
      .min(1, t`Phone number is required`)
      .refine(phoneNumber => isValidPhoneNumber(phoneNumber), {
        message: t`Phone number is invalid`,
      }),
    shopperStatement: z
      .string()
      .min(1, t`Shopper statement is required`)
      .max(22, t`Shopper statement cannot be longer than 22 characters`)
      .regex(/\D/, t`Shopper statement cannot be all digits`),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    splitConfigurationGroupId: z.string({required_error: t`Fee Group is not selected`}),
    // biome-ignore lint/style/useNamingConvention: <explanation>
    transferInstrumentId: z.string({required_error: t`Transfer instrument is not selected`}),
    industryCodes: z
      // biome-ignore lint/style/useNamingConvention: <explanation>
      .array(z.string(), {required_error: t`Bussiness Line is required`})
      .min(1, t`Bussiness Line is required`),
  });

export type AddStoreFormData = z.infer<ReturnType<typeof getAddStoreFormSchema>>;

type AddStoreFormProps = {
  form: UseFormReturn<AddStoreFormData>;
  splitConfigurationGroups: SplitConfigurationGroupListItemOutput[];
  countryCode: string;
  industryCodes: string[];
  appliedIndustryCodes: string[];
  transferInstruments: Array<{
    id: string;
    bankAccountLast4: string | null;
    bankAccountType: string | null;
  }>;
  editMode?: boolean;
};

export const AddStoreForm = ({
  form,
  splitConfigurationGroups,
  countryCode,
  transferInstruments,
  industryCodes,
  editMode,
  appliedIndustryCodes,
}: AddStoreFormProps) => {
  const {
    register,
    setValue,
    watch,
    control,
    formState: {errors},
  } = form;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    initialize: initGoogleMapsApi,
    getAddressPredictions,
    getAddressDetails,
  } = useAddressAutocomplete({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    countryRestrictions: [countryCode],
  });

  const [predictions, setPredictions] = useState<AddressPrediction[]>([]);

  const splitConfigurationGroupId = watch('splitConfigurationGroupId');

  const onAddNewFeeGroupClick = useCallback(() => {
    const returnTo = `${location.pathname}${location.search}`;
    const formData = watch();
    // save a state for back button
    navigate(returnTo, {replace: true, state: {addStoreFormData: formData}});

    navigate(
      `${RoutePath.TRANSACTION_FEE_GROUP_CREATE}?sourceFeeGroupId=${splitConfigurationGroupId}`,
      {
        state: {returnTo, returnToState: {addStoreFormData: formData}},
      }
    );
  }, [location.pathname, location.search, splitConfigurationGroupId, watch]);

  const handleAddressLineChange = async (address: string) => {
    setValue('businessAddress.line1', address);
    if (address.length === 0) return;
    const predictionItems = await getAddressPredictions(address);
    setPredictions(predictionItems);
  };

  const onSelectAddress = async (value: string, onChange: (v: string) => void) => {
    const addressId = Array.isArray(value) ? value[0] : value;
    const prediction = predictions.find(prediction => prediction.id === addressId);
    if (!prediction) {
      onChange(value);
      return;
    }

    // Get full address details only when a prediction is selected
    const address = await getAddressDetails(prediction.id);

    onChange(address.street || '');
    setValue('businessAddress.city', address.city || '', {shouldValidate: true});
    setValue('businessAddress.state', address.stateOrProvince || '', {shouldValidate: true});
    setValue('businessAddress.postalCode', address.postalCode || '', {shouldValidate: true});
  };

  const handleIndustryCodesChange = async (selectedIndustryCode: string[]) => {
    // do not allow to desellect applied industry codes
    const newIndustryCodes = [
      ...appliedIndustryCodes,
      ...selectedIndustryCode.filter(bl => !appliedIndustryCodes.includes(bl)),
    ];
    setValue('industryCodes', newIndustryCodes, {shouldValidate: true});
  };

  useEffect(() => {
    initGoogleMapsApi();
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <Label as="div" text={t`Display Name`}>
        <InputText
          {...register('displayName')}
          hasError={Boolean(errors.displayName)}
          placeholder={t`e.g. Boston 1164 Aspen Court`}
        />
        <ValidationError isVisible={Boolean(errors.displayName)}>
          {errors.displayName?.message}
        </ValidationError>
      </Label>
      <Label as="div" text={t`Reference ID (Optional)`}>
        <InputText
          {...register('storeReferenceId')}
          readOnly={editMode}
          disabled={editMode}
          className={editMode ? 'read-only:bg-gray-100' : ''}
          hasError={Boolean(errors.storeReferenceId)}
        />
        <ValidationError isVisible={Boolean(errors.storeReferenceId)}>
          {errors.storeReferenceId?.message}
        </ValidationError>
      </Label>
      <div className="mt-8 col-span-full">
        <Controller
          name="businessAddress.line1"
          control={control}
          render={({field}) => (
            <Typeahead
              {...field}
              onSelect={(value: string) => {
                onSelectAddress(value, field.onChange);
              }}
              className="mb-2"
              label={t`Street Address`}
              options={predictions.map(item => ({
                id: item.id,
                label: item.description,
              }))}
              placeholder={t`Search...`}
              onChange={handleAddressLineChange}
            />
          )}
        />
        <ValidationError isVisible={Boolean(errors.businessAddress?.line1)}>
          {errors.businessAddress?.line1?.message}
        </ValidationError>
      </div>
      <Label as="div" text={t`Line 2`}>
        <InputText {...register('businessAddress.line2', {required: true})} />
        <ValidationError isVisible={Boolean(errors.businessAddress?.line2)}>
          {errors.businessAddress?.line2?.message}
        </ValidationError>
      </Label>

      <div className="grid grid-cols-3 gap-x-6 gap-y-3">
        <div className="sm:col-span-1 sm:col-start-1">
          <Label as="div" text={t`City`}>
            <InputText
              {...register('businessAddress.city', {required: true})}
              inputClassName="w-full"
            />
            <ValidationError isVisible={Boolean(errors.businessAddress?.city)}>
              {errors.businessAddress?.city?.message}
            </ValidationError>
          </Label>
        </div>
        {countryCode !== 'GB' && (
          <div className="sm:col-span-1">
            <Label as="div" text={t`State / Province`}>
              <InputText
                {...register('businessAddress.state', {required: true})}
                inputClassName="w-full"
              />
              <ValidationError isVisible={Boolean(errors.businessAddress?.state)}>
                {errors.businessAddress?.state?.message}
              </ValidationError>
            </Label>
          </div>
        )}
        <div className="sm:col-span-1">
          <Label as="div" text={t`ZIP / Postal code`}>
            <InputText
              {...register('businessAddress.postalCode', {required: true})}
              inputClassName="w-full"
            />
            <ValidationError isVisible={Boolean(errors.businessAddress?.postalCode)}>
              {errors.businessAddress?.postalCode?.message}
            </ValidationError>
          </Label>
        </div>
      </div>
      <div className="mt-8">
        <Label as="div" text={t`Business Phone Number`}>
          <InputPhone name="businessPhoneNumber" control={control} />
          <ValidationError isVisible={Boolean(errors.businessPhoneNumber)}>
            {errors.businessPhoneNumber?.message}
          </ValidationError>
        </Label>
      </div>
      <div className="sm:col-span-4">
        <Label as="div" text={t`Shopper Statement for CC Purchases`}>
          <InputText {...register('shopperStatement', {required: true})} />
          <ValidationError isVisible={Boolean(errors.shopperStatement)}>
            {errors.shopperStatement?.message}
          </ValidationError>
        </Label>
      </div>
      <Label as="div" text={t`Business Line`} className="mt-8">
        <MultiEntityPicker
          className="w-full"
          selected={watch('industryCodes')}
          onChange={handleIndustryCodesChange}
          options={industryCodes.map(industryCode => ({
            id: industryCode,
            name:
              allIndustryGroups[industryCode as keyof typeof allIndustryGroups] || t`Unrecognized`,
          }))}
          label={t`Select Business Line`}
        />
        <p className="text-xs leading-5 text-gray-500">
          <Trans>Bussiness Lines can only be added, not removed from the store</Trans>
        </p>
        <ValidationError isVisible={Boolean(errors.industryCodes)}>
          {errors.industryCodes?.message}
        </ValidationError>
      </Label>
      <Label as="div" text={t`Bank Account`}>
        <InputSelect
          label=""
          value={watch('transferInstrumentId')}
          labelClassName="gap-0"
          placeholder={t`Select Bank Account`}
          className="w-full"
          onChange={item =>
            !Array.isArray(item)
              ? setValue('transferInstrumentId', item, {shouldValidate: true})
              : undefined
          }
          options={transferInstruments.map(transferInstrument => ({
            id: transferInstrument.id,
            label:
              transferInstrument.bankAccountType && transferInstrument.bankAccountLast4
                ? `${capitalize(transferInstrument.bankAccountType)} (*${
                    transferInstrument.bankAccountLast4
                  })`
                : transferInstrument.id,
          }))}
        />
        <ValidationError isVisible={Boolean(errors.transferInstrumentId)}>
          {errors.transferInstrumentId?.message}
        </ValidationError>
      </Label>
      <Label
        as="div"
        text={
          <div className="flex justify-between">
            <span>
              <Trans>Transaction Fee Group</Trans>
            </span>
            <button
              type="button"
              className="text-sm cursor-pointer text-primary-600 w-fit"
              onClick={onAddNewFeeGroupClick}
            >
              <Trans>+ Add new</Trans>
            </button>
          </div>
        }
        className="mt-8 mb-10"
      >
        <InputSearchSelect
          className="mb-2"
          value={splitConfigurationGroupId}
          onChange={val =>
            setValue(
              'splitConfigurationGroupId',
              Array.isArray(val) ? (val[val.length - 1] as string) : val,
              {shouldValidate: true}
            )
          }
          options={splitConfigurationGroups.map(group => ({id: group.id, label: group.name}))}
          placeholder={t`Please select fee group`}
        />
        <ValidationError isVisible={Boolean(errors.splitConfigurationGroupId)}>
          {errors.splitConfigurationGroupId?.message}
        </ValidationError>
      </Label>
    </div>
  );
};
