import {trpc} from '@/api/trpcClient';
import {MerchantsRevenueList, OrganizationsRevenueList} from '@/components';
import {RoutePath} from '@/components/layout/navigation';
import {exportToCsvMerchants} from '@/pages/customers/csv-export';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {formatLocaleDate, profitabilitySearchParamsSchema} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  DashboardDatepicker,
  EntityPicker,
  NavigationTabs,
  Typography,
  getDashboardDatepickerDefaults,
  useNotification,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';
import {exportMerchantsRevenueCSV} from './export-merchants-revenue-csv';
import {exportOrganizationsRevenueCSV} from './export-organizations-revenue-csv';

const getBreadCrumbs = () => [
  {name: t`Reports`, href: RoutePath.REPORTS, current: false, disabled: true},
  {name: t`Transaction Profitability`, href: RoutePath.REPORTS_PROFITABILITY, current: true},
];

const pageSize = 25;

export const ProfitabilityReportPage = () => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {i18n} = useLingui();
  const {orgsWithBoardedMerchants: organizationList, pspMerchantAccountName, currency} = useStore();
  const [merchantPagination, setMerchantPagination] = useState({pageIndex: 0, pageSize});
  const [organizationPagination, setOrganizationPagination] = useState({pageIndex: 0, pageSize});
  const [isCsvLoading, setCsvLoading] = useState(false);

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    profitabilitySearchParamsSchema
  );

  const selectedOrganization = typedSearchParams?.organizationId;
  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;
  const dateValue = startDate && endDate ? {startDate, endDate} : getDashboardDatepickerDefaults();
  const merchantSort =
    typedSearchParams.merchantSortColumnId && typedSearchParams.merchantSortValue
      ? {
          columnId: typedSearchParams.merchantSortColumnId,
          value: typedSearchParams.merchantSortValue,
        }
      : null;
  const organizationSort =
    typedSearchParams.organizationSortColumnId && typedSearchParams.organizationSortValue
      ? {
          columnId: typedSearchParams.organizationSortColumnId,
          value: typedSearchParams.organizationSortValue,
        }
      : null;
  const selectedNavigationTab = typedSearchParams.selectedNavigationTab || 'organizations';

  const trpcContext = trpc.useUtils();
  const {data: organizationRevenueListData, isLoading: organizationRevenueListLoading} =
    trpc.payment.getOrganizationsRevenueList.useQuery(
      {
        ...organizationPagination,
        fromDate: dateValue.startDate,
        toDate: dateValue.endDate,
        organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
        orderBy: organizationSort,
        pspMerchantAccountName,
      },
      {enabled: !selectedOrganization}
    );
  const {data: merchantsRevenueListData, isLoading: merchantsRevenueListLoading} =
    trpc.payment.getMerchantsRevenueList.useQuery({
      ...merchantPagination,
      fromDate: dateValue.startDate,
      toDate: dateValue.endDate,
      organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
      orderBy: merchantSort,
      pspMerchantAccountName,
    });

  const handleOrganizationChange = useCallback(
    (organizationId?: string) => {
      setTypedSearchParams({organizationId});
      setMerchantPagination({pageIndex: 0, pageSize});
      setOrganizationPagination({pageIndex: 0, pageSize});
    },
    [pageSize, setTypedSearchParams, setMerchantPagination, setOrganizationPagination]
  );

  const handleDateChange = useCallback(
    (dateRange: {startDate: Date; endDate: Date}) => {
      setTypedSearchParams(dateRange);
      setMerchantPagination({pageIndex: 0, pageSize});
      setOrganizationPagination({pageIndex: 0, pageSize});
    },
    [pageSize, setTypedSearchParams, setMerchantPagination, setOrganizationPagination]
  );

  const handleMerchantsCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullMerchantsList = await trpcContext.payment.getMerchantsRevenueList.fetch({
        fromDate: dateValue.startDate,
        toDate: dateValue.endDate,
        organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
        pspMerchantAccountName,
      });
      exportMerchantsRevenueCSV(fullMerchantsList, dateValue.startDate, dateValue.endDate, i18n);
      showSuccessNotification(t`Merchants .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Merchants .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, trpcContext, exportToCsvMerchants, dateValue, i18n, selectedOrganization]);

  const handleOrganizaionsCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullOrganizationsList = await trpcContext.payment.getOrganizationsRevenueList.fetch({
        fromDate: dateValue.startDate,
        toDate: dateValue.endDate,
        organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
        pspMerchantAccountName,
      });
      exportOrganizationsRevenueCSV(
        fullOrganizationsList,
        dateValue.startDate,
        dateValue.endDate,
        i18n,
        currency
      );
      showSuccessNotification(t`Organizations .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Organizations .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, trpcContext, exportToCsvMerchants, dateValue, i18n, selectedOrganization]);

  return (
    <>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex flex-col justify-between gap-2 pt-4 xl:flex-row xl:flex-wrap 2xl:flex-nowrap">
        <div className="w-full">
          <Typography variant="header-page">
            <Trans>Transaction Profitability For</Trans>{' '}
            {formatLocaleDate(dateValue.startDate, 'short')}
            {' - '}
            {formatLocaleDate(dateValue.endDate, 'short')}
          </Typography>
        </div>
        <div className="flex flex-col items-center justify-between gap-2 max-2xl:w-full md:flex-row md:flex-wrap shrink-0">
          <div className="w-full mt-4 md:mt-0 md:w-60">
            <EntityPicker
              selected={selectedOrganization || undefined}
              onChange={handleOrganizationChange}
              options={organizationList}
            />
          </div>
          <div className="z-20 w-full mt-4 md:mt-0 md:w-64">
            <DashboardDatepicker dateRange={dateValue} onChange={handleDateChange} />
          </div>
        </div>
        <Button
          type="button"
          variant="primary"
          size="md"
          className="flex md:hidden w-fit max-md:w-full"
          isLoading={isCsvLoading}
          onClick={handleOrganizaionsCsvExport}
          disabled={!organizationRevenueListData || organizationRevenueListData?.rows.length === 0}
        >
          <Trans>Export to CSV</Trans>
        </Button>
      </div>
      <div className="mt-5">
        <NavigationTabs
          items={[
            ...(!selectedOrganization
              ? [
                  {
                    title: t`Organizations`,
                    key: 'organizations' as const,
                  },
                ]
              : []),
            {
              title: t`Merchants`,
              key: 'merchants',
            },
          ]}
          onChange={selectedNavigationTab => setTypedSearchParams({selectedNavigationTab})}
          activeTabKey={!selectedOrganization ? selectedNavigationTab : 'merchants'}
        />
        <div className="overflow-hidden bg-white rounded-b-lg rounded-tr-lg shadow ">
          <div className="px-4 py-5 sm:px-6">
            {selectedNavigationTab === 'organizations' && !selectedOrganization ? (
              <div>
                <div className="flex items-center justify-between mb-5">
                  <Typography variant="header-section">
                    <Trans>Organizations Report</Trans>
                  </Typography>
                  <Button
                    type="button"
                    variant="primary"
                    size="md"
                    className="hidden md:block w-fit"
                    isLoading={isCsvLoading}
                    onClick={handleOrganizaionsCsvExport}
                    disabled={
                      !organizationRevenueListData || organizationRevenueListData?.rows.length === 0
                    }
                  >
                    <Trans>Export to CSV</Trans>
                  </Button>
                </div>
                <OrganizationsRevenueList
                  data={organizationRevenueListData}
                  sort={organizationSort}
                  setSort={sort =>
                    setTypedSearchParams({
                      organizationSortColumnId: sort?.columnId,
                      organizationSortValue: sort?.value,
                    })
                  }
                  isLoading={organizationRevenueListLoading}
                  pagination={organizationPagination}
                  onPaginationChange={setOrganizationPagination}
                  allowSorts
                  currency={currency}
                  showReferenceId
                />
              </div>
            ) : (
              <div>
                <div className="flex items-center justify-between mb-5">
                  <Typography variant="header-section">
                    <Trans>Merchants Report</Trans>
                  </Typography>
                  <Button
                    type="button"
                    variant="primary"
                    size="md"
                    className="hidden w-fit md:block"
                    isLoading={isCsvLoading}
                    onClick={handleMerchantsCsvExport}
                    disabled={
                      !merchantsRevenueListData || merchantsRevenueListData?.rows.length === 0
                    }
                  >
                    <Trans>Export to CSV</Trans>
                  </Button>
                </div>
                <MerchantsRevenueList
                  data={merchantsRevenueListData}
                  sort={merchantSort}
                  setSort={sort =>
                    setTypedSearchParams({
                      merchantSortColumnId: sort?.columnId,
                      merchantSortValue: sort?.value,
                    })
                  }
                  isLoading={merchantsRevenueListLoading}
                  pagination={merchantPagination}
                  onPaginationChange={setMerchantPagination}
                  allowSorts
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
