import {useLingui} from '@lingui/react';
import {InputSearchSelectFilter} from '../';

const ALL_ORGS = 'all';

type Props<T extends string> = {
  label?: string;
  allLabel?: string;
  selected?: T | null;
  className?: string;
  options: {
    id: T;
    name: string;
    className?: string;
    classNameActive?: string;
    tooltipText?: string;
  }[];
  onChange: (id?: T) => void;
  contentClass?: string;
  hideAllOption?: boolean;
};

export const EntityPickerFilter = <T extends string>({
  label,
  allLabel,
  options,
  onChange,
  className,
  selected,
  contentClass,
  hideAllOption,
}: Props<T>) => {
  const transformedOptions = options.map(({id, name, className, classNameActive, tooltipText}) => ({
    id: id as T | 'all',
    label: name,
    className,
    classNameActive,
    tooltipText,
  }));
  const {i18n} = useLingui();
  return (
    <InputSearchSelectFilter
      label=""
      value={selected ?? ALL_ORGS}
      labelClassName="gap-0"
      placeholder={label || i18n._('Organization')}
      className={className ? className : 'mt-0'}
      contentClass={contentClass}
      onChange={item =>
        onChange(!Array.isArray(item) && item === ALL_ORGS ? undefined : (item as T))
      }
      options={[
        ...(!hideAllOption
          ? [
              {
                id: ALL_ORGS as T | 'all',
                label: allLabel || label || i18n._('All Organizations'),
              },
            ]
          : []),
        ...transformedOptions,
      ]}
      defaultValue={ALL_ORGS}
    />
  );
};
