import {LocaleCode} from '../../constants';

export function formatPercentageDifference(
  oldValue: number,
  currentValue: number,
  locale: LocaleCode,
  decimals: number | undefined = 2
) {
  if (oldValue === 0 || Number.isNaN(currentValue) || Number.isNaN(currentValue)) {
    return '';
  }
  const percentage = (currentValue - oldValue) / oldValue;
  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(percentage);
}
