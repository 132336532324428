import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {PaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CurrencyCode,
  ErrorCode,
  LocaleCode,
  formatAmount,
  isFormattedTrpcError,
} from '@zentact/common';
import {useCallback} from 'react';
import {AlertOverlayWithConfirmation, RefundOverlay, useNotification} from '../..';

type RefundConfirmDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => unknown;
  paymentDetailsRow: Pick<
    PaymentListItemOutput,
    'pspReferenceId' | 'authorizedAmount' | 'refundedAmount' | 'currency' | 'status'
  >;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  isTenant?: boolean;
};

export const RefundConfirmDialog = ({
  isOpen,
  onClose,
  onSuccess,
  paymentDetailsRow,
  trpc,
  isTenant = false,
}: RefundConfirmDialogProps) => {
  const {i18n} = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const {pspReferenceId, authorizedAmount, refundedAmount, currency, status} = paymentDetailsRow;

  const isPaymentAuthorized = status === 'AUTHORIZED';
  const remainedAmount = authorizedAmount - refundedAmount;
  const formattedAuthorizedAmount = formatAmount(
    authorizedAmount,
    i18n.locale as LocaleCode,
    currency as CurrencyCode
  );
  const formattedRemainedAmount = formatAmount(
    remainedAmount,
    i18n.locale as LocaleCode,
    currency as CurrencyCode
  );

  const refundOrVoidPaymentMutation = trpc.payment.refundOrVoidPayment.useMutation();

  const handleRefund = useCallback(
    async (amount?: number) => {
      return new Promise<void>(resolve => {
        refundOrVoidPaymentMutation.mutate(
          {
            pspReferenceId,
            ...(amount && {amount: amount}),
          },
          {
            onSuccess() {
              showSuccessNotification(
                !isPaymentAuthorized
                  ? {
                      title: i18n._('Refund request successfully created'),
                      message: !isTenant
                        ? i18n._(
                            "You'll receive an email confirmation once the refund is processed."
                          )
                        : undefined,
                    }
                  : {
                      title: i18n._('Void request successfully created'),
                      message: !isTenant
                        ? i18n._("You'll receive an email confirmation once the void is processed.")
                        : undefined,
                    }
              );
              onSuccess();
              resolve();
            },
            onError(error) {
              const type = !isPaymentAuthorized ? i18n._('refund') : i18n._('void');
              if (isFormattedTrpcError(error)) {
                if (error.data.errorCode === ErrorCode.PAYMENT_NOT_AVAILABLE_TO_REFUND_OR_VOID) {
                  showErrorNotification(i18n._('The payment is not available to {type}', {type}));
                  return;
                }
                if (error.data.errorCode === ErrorCode.MERCHANT_ACCOUNT_IS_NOT_ACTIVE) {
                  showErrorNotification(
                    i18n._('Merchant account must be active to perform a refund/void operation.')
                  );
                  return;
                }
                if (error.data.errorCode === ErrorCode.PARTIAL_REFUND_NOT_AVAILABLE) {
                  showErrorNotification(
                    i18n._('Partial refund is not available at the moment. Please try again later.')
                  );
                  return;
                }
              }

              showErrorNotification(i18n._('Failed to create {type} request', {type}));
              resolve();
            },
          }
        );
      });
    },
    [pspReferenceId, isPaymentAuthorized, showErrorNotification, showSuccessNotification]
  );
  return (
    <div>
      {isPaymentAuthorized ? (
        <AlertOverlayWithConfirmation
          open={isOpen}
          setOpen={onClose}
          handleAction={handleRefund}
          localeText={{
            title: i18n._('Void Transaction ({pspReferenceId})', {pspReferenceId}),
            description: i18n._('Are you sure you want to void - {amount}?', {
              amount: formattedAuthorizedAmount,
            }),
            confirm: i18n._('Void'),
            cancel: i18n._('Cancel'),
          }}
        />
      ) : (
        <RefundOverlay
          open={isOpen}
          setOpen={onClose}
          handleAction={handleRefund}
          localeText={{
            title: i18n._('Refund Transaction'),
            description: i18n._(
              'How much would you like to refund? Transaction {pspReferenceId} - {amount}?',
              {
                amount: formattedRemainedAmount,
                pspReferenceId: pspReferenceId,
              }
            ),
            confirm: i18n._('Refund'),
            cancel: i18n._('Cancel'),
          }}
          i18n={i18n}
          remainedAmount={remainedAmount}
          currency={currency as CurrencyCode}
        />
      )}
    </div>
  );
};
