import {VolumeComparisonChartMode} from '@zentact/common';
import * as z from 'zod';

export const volumeComparisonSearchParamsSchema = z.object({
  organizationId: z.string().nullable().optional(),
  merchantAccountId: z.string().nullable().optional(),
  storeId: z.string().nullable().optional(),
  mode: z
    .nativeEnum(VolumeComparisonChartMode)
    .default(VolumeComparisonChartMode.WEEK_BY_DAYS)
    .optional(),
});

export type VolumeComparisonSearchParamsSchema = z.infer<typeof volumeComparisonSearchParamsSchema>;
