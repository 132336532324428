import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {StoresListItem} from '@zentact/api/src/trpc/routers/storeRouter';
import {storesFiltersSchema} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  ResetTableFiltersButton,
  SlideOverWithBrandedHeader,
  StoreFilters,
  TableListSort,
  TableSortValue,
  Typography,
  useNotification,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {AssignStoreTransactionFeeGroupPanel} from './assign-store-transaction-fee-group-panel';
import {exportToCsvStores} from './csv-export';
import {EditStorePanel} from './edit-store';
import {StoresList} from './stores-list';
import {UpdateSalesDayClosingTime} from './update-sales-day-closing-time';
import {UpdateStoreSettlementDelayDays} from './update-store-settlement-delay-days';

const breadcrumbs = () => [
  {name: t`Customers`, href: RoutePath.CUSTOMERS_MERCHANTS, current: false},
  {name: t`Merchants`, href: '#', current: true},
];

const adminStoresSearchParamsSchema = z.intersection(
  storesFiltersSchema,
  z.object({
    filtersFormOpen: z
      .boolean()
      .or(z.string().transform(value => value === 'true'))
      .nullable()
      .optional(),
  })
);

export const Stores = () => {
  const {
    orgsWithBoardedMerchants: organizationList,
    pspMerchantAccountName,
    activeAndInactiveMerchantAccounts: merchantAccounts,
  } = useStore();
  const navigate = useNavigate();

  const {typedSearchParams: typedSearchParamsAll, setTypedSearchParams} = useTypedSearchParams(
    adminStoresSearchParamsSchema
  );

  const {filtersFormOpen, ...typedSearchParams} = typedSearchParamsAll;
  const {
    organizationId,
    selectedMerchantAccount,
    displayName,
    statuses,
    storeId,
    storeReferenceId,
    splitConfigurationGroupId,
  } = typedSearchParams;

  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const storesList = trpc.store.getStoresList.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      where: {
        ...(organizationId && {organizationId}),
        ...(storeId && {storeId}),
        ...(storeReferenceId && {storeReferenceId}),
        ...(splitConfigurationGroupId && {splitConfigurationGroupId}),
        ...(statuses?.length && {statuses}),
        ...(selectedMerchantAccount && {selectedMerchantAccount}),
        ...(displayName && {displayName}),
        pspMerchantAccountName,
      },
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
    }
  );

  const {
    data: assignStoreFeeGroupData,
    on: openAssignStoreFeeGroupPanel,
    off: closeAssignStoreFeeGroupPanel,
  } = useToggleWithData<StoresListItem | null>();

  const {
    data: updateSettlementDelayDays,
    on: openUpdateSettlementDelayDays,
    off: closeUpdateSettlementDelayDays,
  } = useToggleWithData<StoresListItem | null>();

  const {
    data: updateSalesDayClosingTime,
    on: openUpdateSalesDayClosingTime,
    off: closeUpdateSalesDayClosingTime,
  } = useToggleWithData<StoresListItem | null>();

  const {
    data: editStorePanelId,
    on: openEditStorePanel,
    off: closeEditStorePanel,
  } = useToggleWithData<string>();

  const handleSelectStore = useCallback(
    (row: StoresListItem) => {
      navigate(generatePath(RoutePath.STORE_DETAILS, {storeId: row.id}));
    },
    [navigate]
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  const trpcContext = trpc.useUtils();
  const {i18n} = useLingui();
  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    if (!pspMerchantAccountName) {
      console.error('pspMerchantAccountName is not defined');
      return;
    }
    setCsvLoading(true);
    try {
      const fullStoresList = await trpcContext.store.getStoresList.fetch({
        ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
        where: {
          ...(organizationId && {organizationId}),
          ...(storeId && {storeId}),
          ...(storeReferenceId && {storeReferenceId}),
          ...(splitConfigurationGroupId && {splitConfigurationGroupId}),
          ...(statuses?.length && {statuses}),
          ...(selectedMerchantAccount && {selectedMerchantAccount}),
          ...(displayName && {displayName}),
          pspMerchantAccountName,
        },
      });
      exportToCsvStores(fullStoresList, i18n);
      showSuccessNotification(t`Payments .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Payments .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, trpcContext, exportToCsvStores, sort, typedSearchParams]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 py-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Stores</Trans>
        </Typography>
        <div className="flex items-end justify-end gap-2 py-4 max-sm:flex-col sm:items-center">
          <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center max-sm:w-full">
            <Button
              type="button"
              variant="primary"
              size="md"
              className="w-fit max-sm:w-full"
              isLoading={isCsvLoading}
              onClick={handleCsvExport}
              disabled={!storesList.data || storesList.data.rows.length === 0}
            >
              <Trans>Export to CSV</Trans>
            </Button>
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-2 font-normal max-sm:flex-wrap sm:items-center">
        <TableListSort
          options={[
            {
              id: 'organizationName',
              label: i18n._('Organization'),
            },
            {
              id: 'updatedAt',
              label: i18n._('Last Active'),
            },
          ]}
          sort={sort}
          setSort={setSort}
        />
        <div className="flex justify-between gap-2">
          <div className="lg:hidden">
            <ResetTableFiltersButton
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <Button
            type="button"
            size="sm"
            onClick={() => setTypedSearchParams({filtersFormOpen: true})}
            className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
          >
            <Trans>Filter</Trans>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
        </div>
        <div className="hidden lg:block">
          <StoreFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            organizationId={organizationId}
            storeId={storeId}
            storeReferenceId={storeReferenceId}
            statuses={statuses}
            selectedMerchantAccount={selectedMerchantAccount}
            merchantAccounts={merchantAccounts}
            organizationList={organizationList}
            displayName={displayName}
          />
        </div>
        <SlideOverWithBrandedHeader
          isOpen={!!filtersFormOpen}
          title={'Filters'}
          closeHandler={() => setTypedSearchParams({filtersFormOpen: false})}
          panelClassName="w-screen pointer-events-auto lg:max-w-md"
        >
          <StoreFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            organizationId={organizationId}
            statuses={statuses}
            selectedMerchantAccount={selectedMerchantAccount}
            merchantAccounts={merchantAccounts}
            organizationList={organizationList}
            displayName={displayName}
          />
        </SlideOverWithBrandedHeader>
      </div>
      <div className="mt-4">
        {/* @ts-ignore */}
        <StoresList
          storesList={storesList.data}
          refetch={storesList.refetch}
          filters={typedSearchParams || {}}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetailsPanel={handleSelectStore}
          openAssignStoreFeeGroupPanel={openAssignStoreFeeGroupPanel}
          openUpdateSettlementDelayDays={openUpdateSettlementDelayDays}
          openUpdateSalesDayClosingTime={openUpdateSalesDayClosingTime}
          openEditStorePanel={openEditStorePanel}
          isLoading={storesList.isLoading}
        />
      </div>
      {assignStoreFeeGroupData && (
        <AssignStoreTransactionFeeGroupPanel
          storeRow={assignStoreFeeGroupData}
          onClose={closeAssignStoreFeeGroupPanel}
          isOpen={!!assignStoreFeeGroupData}
          triggerRefetch={storesList.refetch}
        />
      )}
      {updateSettlementDelayDays && (
        <UpdateStoreSettlementDelayDays
          storeRow={updateSettlementDelayDays}
          onClose={closeUpdateSettlementDelayDays}
          isOpen={!!openUpdateSettlementDelayDays}
          triggerRefetch={storesList.refetch}
        />
      )}
      {updateSalesDayClosingTime && (
        <UpdateSalesDayClosingTime
          storeRow={updateSalesDayClosingTime}
          onClose={closeUpdateSalesDayClosingTime}
          isOpen={!!openUpdateSalesDayClosingTime}
          triggerRefetch={storesList.refetch}
        />
      )}
      {editStorePanelId && (
        <EditStorePanel
          storeId={editStorePanelId}
          isOpen={!!openEditStorePanel}
          onClose={closeEditStorePanel}
          triggerRefetch={storesList.refetch}
        />
      )}
    </div>
  );
};
