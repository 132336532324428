import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {Button, InputText, Label, ValidationError, useNotification} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import z from 'zod';

const themeIdFormSchema = () =>
  z.object({
    themeId: z.string().max(50, t`Theme Id must be less than 50 characters`).or(z.null()),
  });

export type ThemeIdFormData = z.infer<ReturnType<typeof themeIdFormSchema>>;

type Props = {
  defaultThemeId: string | null;
};

export const ThemeIdForm = ({defaultThemeId}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<ThemeIdFormData>({
    resolver: zodResolver(themeIdFormSchema()),
    defaultValues: {
      themeId: defaultThemeId ?? undefined,
    },
  });
  const {tenantRefetch} = useStore();

  const trpcContext = trpc.useUtils();

  const updateTenantAdyenConfiguration = trpc.tenant.updateTenantAdyenConfiguration.useMutation({
    onSuccess: updatedTenant => {
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
      tenantRefetch();
      showSuccessNotification(t`Theme id updated`);
    },
    onError: () => {
      showErrorNotification(
        t`Failed to update theme id`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback((data: ThemeIdFormData) => {
    updateTenantAdyenConfiguration.mutate({
      hopThemeId: data.themeId ? data.themeId : null,
    });
  }, []);

  return (
    <form className="flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="px-4 py-5 space-y-6 sm:px-6">
        <div className="flex flex-col w-full gap-6 md:w-1/2">
          <div className="space-y-4 sm:col-span-3">
            <Label text={t`Theme Id`}>
              <InputText
                type="text"
                {...register('themeId', {
                  required: true,
                })}
                hasError={Boolean(errors.themeId)}
                placeholder={t`123345678`}
              />
              <ValidationError isVisible={Boolean(errors.themeId)}>
                {errors.themeId?.message}
              </ValidationError>
            </Label>
          </div>
        </div>
        <footer className="flex justify-end mt-8">
          <Button
            size="lg"
            type="submit"
            className="w-fit"
            disabled={updateTenantAdyenConfiguration.isLoading}
            isLoading={updateTenantAdyenConfiguration.isLoading}
          >
            <Trans>Save</Trans>
          </Button>
        </footer>
      </div>
    </form>
  );
};
