import {ApexOptions} from 'apexcharts';

export type ChartDataWithSettingsType = {
  series: ApexOptions['series'];
  options: ApexOptions;
};

export enum VolumeComparisonChartMode {
  YEAR_BY_MONTHS = 'YEAR_BY_MONTHS',
  MONTH_BY_DAYS = 'MONTH_BY_DAYS',
  WEEK_BY_DAYS = 'WEEK_BY_DAYS',
}

export const adminUiPrimaryColor = '#45988D';
