import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {I18n} from '@lingui/core';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {DEFAULT_CURRENCY, VolumeComparisonChartMode, adminUiPrimaryColor} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  PaymentMethodsPieChart,
  ResetTableFiltersButton,
  SlideOverWithBrandedHeader,
  Typography,
  VolumeComparisonChart,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {useMemo, useState} from 'react';
import {VolumeComparisonFilters} from './volume-comparison-filters';
import {volumeComparisonSearchParamsSchema} from './volume-comparison-search-params-schema';

const getBreadCrumbs = () => [
  {name: t`Reports`, href: RoutePath.REPORTS, current: false, disabled: true},
  {name: t`Volume Comparison Report`, href: RoutePath.REPORTS_VOLUME_COMPARISON, current: true},
];

export const VolumeComparisonReportPage = () => {
  const {locale, pspMerchantAccountName, currency} = useStore();
  const {i18n} = useLingui();

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    volumeComparisonSearchParamsSchema
  );
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const dateValue = useMemo(() => {
    const currentPeriodDateEnd = DateTime.now().endOf('day');
    switch (typedSearchParams.mode) {
      case undefined:
      case VolumeComparisonChartMode.YEAR_BY_MONTHS:
        return {
          currentPeriodDateEnd: currentPeriodDateEnd.toJSDate(),
          currentPeriodDateStart: currentPeriodDateEnd
            .minus({months: 11})
            .startOf('month')
            .toJSDate(),
          previousPeriodDateStart: currentPeriodDateEnd
            .minus({months: 23})
            .startOf('month')
            .toJSDate(),
        };
      case VolumeComparisonChartMode.MONTH_BY_DAYS:
        return {
          currentPeriodDateEnd: currentPeriodDateEnd.toJSDate(),
          currentPeriodDateStart: currentPeriodDateEnd
            .minus({days: 29})
            .startOf('month')
            .toJSDate(),
          previousPeriodDateStart: currentPeriodDateEnd
            .minus({days: 59})
            .startOf('month')
            .toJSDate(),
        };
      case VolumeComparisonChartMode.WEEK_BY_DAYS:
        return {
          currentPeriodDateEnd: currentPeriodDateEnd.toJSDate(),
          currentPeriodDateStart: currentPeriodDateEnd.minus({days: 6}).startOf('month').toJSDate(),
          previousPeriodDateStart: currentPeriodDateEnd
            .minus({days: 13})
            .startOf('month')
            .toJSDate(),
        };
    }
  }, [typedSearchParams.mode]);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {data: currentPeriodSummaryData} = trpc.payment.getPaymentMethodsStats.useQuery({
    fromDate: dateValue.currentPeriodDateStart,
    toDate: dateValue.currentPeriodDateEnd,
    organizationId: typedSearchParams.organizationId ?? undefined,
    pspMerchantAccountName,
    merchantAccountId: typedSearchParams.merchantAccountId ?? undefined,
    storeId: typedSearchParams.storeId ?? undefined,
  });

  const {data: previousPeriodSummaryData} = trpc.payment.getPaymentMethodsStats.useQuery({
    fromDate: dateValue.previousPeriodDateStart,
    toDate: dateValue.currentPeriodDateStart,
    organizationId: typedSearchParams.organizationId ?? undefined,
    pspMerchantAccountName,
    merchantAccountId: typedSearchParams.merchantAccountId ?? undefined,
    storeId: typedSearchParams.storeId ?? undefined,
  });

  return (
    <>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-md:flex-col md:items-center">
        <Typography variant="header-page">
          <Trans>Volume Comparison Report</Trans>
        </Typography>
      </div>
      <div className="mt-4 text-sm leading-6 text-gray-500">
        <Trans>
          This report compares the settled transaction volume between different periods.
        </Trans>
      </div>
      <div className="flex justify-between gap-2 mt-4 font-normal max-sm:flex-wrap sm:items-center">
        <div className="flex justify-between gap-2">
          <div className="lg:hidden">
            <ResetTableFiltersButton
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <Button
            type="button"
            size="sm"
            onClick={() => setIsFiltersOpen(true)}
            className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
          >
            <Trans>Filter</Trans>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
        </div>
        <div className="hidden lg:block">
          <VolumeComparisonFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
          />
        </div>
        <SlideOverWithBrandedHeader
          isOpen={isFiltersOpen}
          title={'Filters'}
          closeHandler={() => setIsFiltersOpen(false)}
          panelClassName="w-screen pointer-events-auto lg:max-w-md"
        >
          <VolumeComparisonFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
          />
        </SlideOverWithBrandedHeader>
      </div>
      <VolumeComparisonChart
        trpc={trpc}
        locale={locale}
        currency={currency ?? DEFAULT_CURRENCY}
        endDate={dateValue.currentPeriodDateEnd}
        timezone={timezone}
        mode={typedSearchParams.mode ?? VolumeComparisonChartMode.WEEK_BY_DAYS}
        primaryColor={adminUiPrimaryColor}
        pspMerchantAccountName={pspMerchantAccountName}
        merchantAccountId={typedSearchParams.merchantAccountId ?? undefined}
        organizationId={typedSearchParams.organizationId ?? undefined}
        storeId={typedSearchParams.storeId ?? undefined}
      />
      <div className="flex flex-col col-span-1 py-5 pl-4 pr-2 mt-5 overflow-hidden bg-white rounded-lg shadow max-md:flex-col content-stretch sm:pl-6 sm:pr-2">
        <Typography variant="header-section">
          <Trans>Payment Methods</Trans>
        </Typography>
        <div className="flex flex-wrap items-center justify-center mt-5 gap-y-5">
          <div className="flex-col justify-between flex-grow">
            <PaymentMethodsPieChart
              paymentMethods={currentPeriodSummaryData?.paymentMethodStats ?? []}
              locale={locale}
              width={300}
              hideLabel
            />
            <div className="w-full mt-2 text-sm text-center">
              {getCurrentPeriodTrans(typedSearchParams.mode, i18n)}
            </div>
          </div>
          <div className="flex flex-col justify-between flex-grow">
            <PaymentMethodsPieChart
              paymentMethods={previousPeriodSummaryData?.paymentMethodStats ?? []}
              locale={locale}
              width={300}
              hideLabel
            />
            <div className="w-full mt-2 text-sm text-center">
              {getPreviousPeriodTrans(typedSearchParams.mode, i18n)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const getCurrentPeriodTrans = (mode: VolumeComparisonChartMode | undefined, i18n: I18n) => {
  switch (mode) {
    case VolumeComparisonChartMode.YEAR_BY_MONTHS:
      return i18n._('This Year');
    case VolumeComparisonChartMode.MONTH_BY_DAYS:
      return i18n._('Last 30 Days');
    case undefined:
    case VolumeComparisonChartMode.WEEK_BY_DAYS:
      return i18n._('Last 7 Days');
  }
};

const getPreviousPeriodTrans = (mode: VolumeComparisonChartMode | undefined, i18n: I18n) => {
  switch (mode) {
    case VolumeComparisonChartMode.YEAR_BY_MONTHS:
      return i18n._('Previous Year');
    case VolumeComparisonChartMode.MONTH_BY_DAYS:
      return i18n._('Previous 30 Days');
    case undefined:
    case VolumeComparisonChartMode.WEEK_BY_DAYS:
      return i18n._('Previous 7 Days');
  }
};
