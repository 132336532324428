import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {useLingui} from '@lingui/react';
import {VolumeComparisonChartMode} from '@zentact/common';
import {
  EntityPickerFilter,
  MerchantAccountsPickerFilter,
  StoresPickerFilter,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect} from 'react';
import {VolumeComparisonSearchParamsSchema} from './volume-comparison-search-params-schema';

type Props = {
  typedSearchParams: VolumeComparisonSearchParamsSchema;
  setTypedSearchParams: (params: VolumeComparisonSearchParamsSchema) => void;
};

export const VolumeComparisonFilters = ({typedSearchParams, setTypedSearchParams}: Props) => {
  const i18n = useLingui();
  const {orgsWithBoardedMerchants: organizationList, pspMerchantAccountName} = useStore();

  const merchantList =
    trpc.merchantAccount.getMerchantAccountsList
      .useQuery(
        {
          where: {
            ...(pspMerchantAccountName && {pspMerchantAccountName}),
            ...(typedSearchParams.organizationId && {
              organizationId: typedSearchParams.organizationId,
            }),
          },
        },
        {enabled: !!pspMerchantAccountName}
      )
      .data?.rows.filter(
        ({merchantAccount}) => merchantAccount?.primaryStore?.id && merchantAccount.businessName
      )
      .map(({merchantAccount}) => merchantAccount) ?? [];

  const storeList =
    trpc.store.getStoresList.useQuery(
      {
        where: {
          ...(pspMerchantAccountName && {pspMerchantAccountName}),
          ...(typedSearchParams.organizationId && {
            organizationId: typedSearchParams.organizationId,
          }),
          ...(typedSearchParams.merchantAccountId && {
            merchantAccountId: typedSearchParams.merchantAccountId,
          }),
        },
      },
      {enabled: !!pspMerchantAccountName}
    ).data?.rows ?? [];

  const handleFilterChange = useCallback(
    <K extends keyof VolumeComparisonSearchParamsSchema>(
      key: K,
      value: VolumeComparisonSearchParamsSchema[K]
    ) => {
      setTypedSearchParams({[key]: value});
    },
    [setTypedSearchParams]
  );

  useEffect(() => {
    const isOrganizationMerchant = merchantList.some(
      merchant => merchant.id === typedSearchParams.merchantAccountId
    );
    if (
      typedSearchParams.organizationId &&
      typedSearchParams.merchantAccountId &&
      !isOrganizationMerchant
    ) {
      setTypedSearchParams({merchantAccountId: null});
    }
  }, [setTypedSearchParams, typedSearchParams.organizationId, typedSearchParams.merchantAccountId]);

  const handleMerchantChange = useCallback(
    (value: string | undefined) => {
      handleFilterChange('merchantAccountId', value);
    },
    [handleFilterChange]
  );

  const handleStoreChange = useCallback(
    (value: string | undefined) => {
      handleFilterChange('storeId', value);
    },
    [handleFilterChange]
  );

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 lg:flex-nowrap">
      <div className="font-normal shrink-0 max-lg:w-full">
        <EntityPickerFilter
          selected={typedSearchParams.mode}
          onChange={value => handleFilterChange('mode', value)}
          options={[
            {id: VolumeComparisonChartMode.WEEK_BY_DAYS, name: i18n._('Last Week')},
            {id: VolumeComparisonChartMode.MONTH_BY_DAYS, name: i18n._('Last 30 Days')},
            {id: VolumeComparisonChartMode.YEAR_BY_MONTHS, name: i18n._('Last Year')},
          ]}
          label={i18n._('Chart Period')}
          hideAllOption
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        <EntityPickerFilter
          selected={typedSearchParams.organizationId}
          onChange={value => handleFilterChange('organizationId', value)}
          options={organizationList}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        <MerchantAccountsPickerFilter
          selectedMerchantAccount={typedSearchParams.merchantAccountId}
          onSelectMerchantAccount={handleMerchantChange}
          merchantAccountsOptions={merchantList}
          allLabel={'Merchant'}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        <StoresPickerFilter
          selectedStore={typedSearchParams.storeId}
          onSelect={handleStoreChange}
          storesOptions={storeList}
          allLabel={'Store'}
          contentClass="right-0 overflow-x-hidden"
        />
      </div>
    </div>
  );
};
