import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {useLingui} from '@lingui/react';
import {TerminalsOutput} from '@zentact/api/src/trpc/routers/terminalRouter';
import {
  Button,
  EntityPicker,
  InputText,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';

type ManageAssignPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  refetchTerminalsList: () => void;
  terminalsRow: TerminalsOutput['rows'][0];
};

const assignTerminalSchema = z.object({
  selectedMerchant: z.string().min(1, 'Merchant selection is required'),
  selectedStore: z.string().min(1, 'Store selection is required'),
});

type AssignTerminalForm = z.infer<typeof assignTerminalSchema>;

export const ManageAssignPanel = ({
  isOpen,
  onCancel,
  terminalsRow,
  refetchTerminalsList,
}: ManageAssignPanelProps) => {
  const {pspMerchantAccountName} = useStore();
  const {i18n} = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const assignTerminalMutation = trpc.terminal.assign.useMutation({
    onSuccess: () => {
      onCancel();
      showSuccessNotification(
        i18n._('Terminal assigned!'),
        i18n._('You have successfully initiated terminal assignment')
      );
      refetchTerminalsList();
    },
    onError: error => {
      onCancel();
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const merchantList =
    trpc.merchantAccount.getMerchantAccountsList
      .useQuery(
        {
          // biome-ignore lint/style/noNonNullAssertion: Checked in the enabled prop
          where: {pspMerchantAccountName: pspMerchantAccountName!},
        },
        {enabled: !!pspMerchantAccountName}
      )
      .data?.rows.reduce<{name: string; id: string; stores: {id: string; name: string}[]}[]>(
        (acc, {merchantAccount}) => {
          if (merchantAccount?.businessName) {
            acc.push({
              name: merchantAccount.businessName,
              id: merchantAccount.id,
              stores: merchantAccount.stores.map(store => ({
                id: store.id,
                name: store.displayName,
              })),
            });
          }
          return acc;
        },
        []
      ) || [];

  const initialMerchant = merchantList.find(merchant =>
    merchant.stores.some(store => store.id === terminalsRow.storeId)
  );

  const initialStore = initialMerchant?.stores.find(store => store.id === terminalsRow.storeId);

  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    reset,
  } = useForm<AssignTerminalForm>({
    resolver: zodResolver(assignTerminalSchema),
    defaultValues: {
      selectedMerchant: initialMerchant?.id || '',
      selectedStore: initialStore?.id || '',
    },
  });

  const selectedMerchant = watch('selectedMerchant');

  const storesList = useMemo(() => {
    const selectedMerchantData = merchantList.find(merchant => merchant.id === selectedMerchant);
    return selectedMerchantData?.stores || [];
  }, [merchantList, selectedMerchant]);

  const onSubmit = (data: AssignTerminalForm) => {
    if (!terminalsRow.id) {
      console.error('Terminals row id is missing');
      return;
    }

    if (data.selectedStore === terminalsRow.storeId) {
      onCancel();
      return;
    }

    assignTerminalMutation.mutate({
      storeId: data.selectedStore,
      terminalId: terminalsRow.id,
    });
    reset();
  };

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      closeHandler={() => {
        onCancel();
        reset();
      }}
      title={i18n._('Assign Terminal')}
      text={i18n._('Choose Merchant to use Terminal')}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onSubmit)}
              isLoading={assignTerminalMutation.isLoading}
            >
              {i18n._('Assign Terminal')}
            </Button>
          </div>
          <Button
            variant="secondary"
            size="lg"
            className="w-fit"
            onClick={() => {
              onCancel();
              reset();
            }}
          >
            {i18n._('Close')}
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-3">
          <Label text={i18n._('Serial Number')}>
            <InputText value={terminalsRow.serialNumber} disabled className="opacity-50" />
            <span className="text-sm leading-4 text-gray-400">
              This can be found on the bottom of the device below the barcode.
            </span>
          </Label>
          <div className="text-sm font-medium leading-6 text-gray-90">{i18n._('Merchant')}</div>
          <Controller
            name="selectedMerchant"
            control={control}
            render={({field}) => {
              const onMerchantChange = (newMerchantId: string | undefined) => {
                field.onChange(newMerchantId);

                const selectedMerchantData = merchantList.find(
                  merchant => merchant.id === newMerchantId
                );
                const firstStore = selectedMerchantData?.stores[0]?.id || '';
                setValue('selectedStore', firstStore);
              };

              return (
                <EntityPicker
                  {...field}
                  className="w-full"
                  excludeDefaultOption
                  options={merchantList}
                  selected={field.value}
                  label={i18n._('Choose a merchant...')}
                  onChange={onMerchantChange}
                />
              );
            }}
          />

          <ValidationError isVisible={Boolean(errors.selectedMerchant)}>
            {errors.selectedMerchant?.message}
          </ValidationError>
          <div className="text-sm font-medium leading-6 text-gray-90">{i18n._('Store')}</div>
          <Controller
            name="selectedStore"
            control={control}
            render={({field}) => (
              <EntityPicker
                {...field}
                className="w-full"
                excludeDefaultOption
                options={storesList}
                selected={field.value}
                label={i18n._('Choose a store...')}
              />
            )}
          />
          <ValidationError isVisible={Boolean(errors.selectedStore)}>
            {errors.selectedStore?.message}
          </ValidationError>
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
