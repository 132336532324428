import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {StoreDetailsOutput, StoresListItem} from '@zentact/api/src/trpc/routers/storeRouter';
import {
  Button,
  InputText,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';

export const getUpdateSalesDayClosingTimeSchema = () =>
  z.object({
    salesDayClosingTime: z
      .string()
      .optional()
      .refine(val => !val || /^0[0-7]:00$/.test(val), {
        message: t`Invalid time format. Must be between 00:00 and 07:00 in HH:MM format.`,
      }),
  });

export type UpdateSalesDayClosingTimeData = z.infer<
  ReturnType<typeof getUpdateSalesDayClosingTimeSchema>
>;

export type UpdateStoreSalesDayClosingTimeProps = {
  storeRow: StoreDetailsOutput | StoresListItem;
  onClose: () => void;
  isOpen: boolean;
  triggerRefetch: () => void;
};

export const UpdateSalesDayClosingTime = ({
  storeRow,
  onClose,
  isOpen,
  triggerRefetch,
}: UpdateStoreSalesDayClosingTimeProps) => {
  const {i18n} = useLingui();
  const {balanceAccountId} = storeRow;
  const currentSalesDayClosingTime = storeRow.balanceAccount?.salesDayClosingTime?.toString();

  const updateSalesDayClosingTimeForm = useForm<UpdateSalesDayClosingTimeData>({
    resolver: zodResolver(getUpdateSalesDayClosingTimeSchema()),
    defaultValues: {
      salesDayClosingTime: currentSalesDayClosingTime,
    },
  });
  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
  } = updateSalesDayClosingTimeForm;
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const salesDayClosingTimeWatch = watch('salesDayClosingTime');

  const updateMerchantSalesDayClosingTime =
    trpc.balanceAccount.updateSalesDayClosingTime.useMutation({
      onSuccess: () => {
        triggerRefetch();
        onClose();
        showSuccessNotification(
          t`Merchant settlement delay updated!`,
          t`You have successfully updated the sales day closing time.`
        );
      },
      onError: error => {
        showErrorNotification(t`Error`, error.message);
      },
    });

  const onUpdateMerchantSalesDayClosingTimeSubmit = useCallback(
    (data: UpdateSalesDayClosingTimeData) => {
      if (!balanceAccountId) {
        return;
      }
      updateMerchantSalesDayClosingTime.mutate({
        balanceAccountId,
        salesDayClosingTime: data.salesDayClosingTime || null,
      });
    },
    [balanceAccountId]
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Adjust Sales Day Closing Time')}
      text={i18n._(
        `You can configure your sales day to end later if your user's business closes after midnight. For example, a restaurant owner that operates from noon to 3:00 AM can set the sales day closing to 3:00 AM. This means that the sales day of that business starts at 3:01 AM and ends at 3:00 AM on the next day.`
      )}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onUpdateMerchantSalesDayClosingTimeSubmit)}
              disabled={salesDayClosingTimeWatch === currentSalesDayClosingTime}
              isLoading={updateMerchantSalesDayClosingTime.isLoading}
            >
              <Trans>Update</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onUpdateMerchantSalesDayClosingTimeSubmit)}>
        <div>
          <div className="flex flex-col gap-3">
            <Label text={t`Sales Day Closing Time`}>
              <Controller
                name="salesDayClosingTime"
                control={control}
                render={({field}) => <InputText {...field} />}
              />
              <ValidationError isVisible={Boolean(errors.salesDayClosingTime)}>
                {errors.salesDayClosingTime?.message}
              </ValidationError>
            </Label>
          </div>
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
