import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {ThemeIdForm} from '@/pages/configure/advanced/forms/theme-id-form';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {allIndustryGroups} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  EnableTenantRefundsToggle,
  Loading,
  SALES_CHANNEL_OPTIONS,
  Typography,
  useDrawerState,
  useNotification,
} from '@zentact/ui-tailwind';
import {AddIndustryCode} from './add-industry-code';
import {CaptureAndSettlementDelay} from './captureAndSettlementDelay';
import {CheckoutDefaults} from './checkout-defaults';
import {BusinessAddressForm} from './forms/business-address-form';
import {CheckoutCustomAttributesForm} from './forms/checkout-custom-attributes';
import {EmailSettingsForm} from './forms/email-settings-form';
import {PhoneNumberSettingsForm} from './forms/phone-number-settings-form';
import {SalesChannelsForm} from './forms/sales-channels-form';
import {TermsAndConditionsForm} from './forms/terms-and-conditions-form';

const getBreadCrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`Advanced`, href: RoutePath.ADVANCED, current: true},
];

export const Advanced = () => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {tenantRefetch} = useStore();
  const {i18n} = useLingui();

  const {data: tenant, isLoading} = trpc.tenant.getContextTenant.useQuery();

  if (isLoading || !tenant) {
    return (
      <div className="flex items-center justify-center h-80">
        <Loading mode="inline" />
      </div>
    );
  }

  const salesChannelsDefaults = tenant.registrationConfiguration.salesChannels.map(key => {
    return key as keyof typeof SALES_CHANNEL_OPTIONS;
  });

  const {
    isOpen: isAddIndustryCodeVisible,
    open: openAddIndustryCodeForm,
    close: closeAddIndustryCodeForm,
    onSuccess: onAddIndustryCodeSuccess,
  } = useDrawerState({
    onSuccessHandler: () => {
      tenantRefetch();
    },
  });

  const removeIndustryCodeMutation = trpc.tenant.removeIndustryCode.useMutation({
    onSuccess: () => {
      showSuccessNotification(t`Industry Code was deleted`);
      tenantRefetch();
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const checkoutAttributesDefaults = tenant.checkoutConfiguration.customAttributesNames;

  return (
    <div>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <Typography variant="header-page" className="pt-4">
        <Trans>Advanced</Trans>
      </Typography>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Sales Channels</Trans>
          </Typography>
        </div>
        <SalesChannelsForm salesChannels={salesChannelsDefaults} />
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Merchant Onboarding Theme ID</Trans>
          </Typography>
          <div className="mt-3 text-gray-700">
            <Trans>
              Enter or update the unique identifier for your Adyen Merchant Onboarding Theme. This
              ID customizes the look and feel of your onboarding process to align with your brand.
              Ensure the ID is valid and matches the theme configured in your Adyen account.
            </Trans>
          </div>
        </div>
        <ThemeIdForm defaultThemeId={tenant?.hopThemeId || null} />
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Refunds</Trans>
          </Typography>
          <div className="mt-3 text-gray-700">
            <Trans>Allow to make refunds from Admin.</Trans>
          </div>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <EnableTenantRefundsToggle
            trpc={trpc}
            enableRefundsInAdmin={tenant.features?.enableRefundsInAdmin ?? false}
          />
        </div>
      </div>
      <CheckoutDefaults
        checkoutConfiguration={tenant.checkoutConfiguration}
        disablePortalPayments={tenant.features?.disablePortalPayments ?? false}
      />
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Checkout Custom Attributes</Trans>
          </Typography>
        </div>
        <CheckoutCustomAttributesForm customAttributesNamesDefaults={checkoutAttributesDefaults} />
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex items-center justify-between">
            <Typography variant="header-section">
              <Trans>Industry Codes</Trans>
            </Typography>
          </div>
          <ul className="divide-y divide-gray-100">
            {tenant.industryCodes.map(code => (
              <li key={code} className="flex justify-between py-5 gap-x-6">
                <div className="flex min-w-0 gap-x-4">
                  <div className="flex-auto min-w-0">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{code}</p>
                    <p className="mt-1 text-xs leading-5 text-gray-500 truncate">
                      {allIndustryGroups[code as keyof typeof allIndustryGroups]}
                    </p>
                  </div>
                </div>
                {tenant.industryCodes.length > 1 && (
                  <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                    <Button
                      variant="primary"
                      size="md"
                      className="bg-red-600 w-fit hover:bg-red-500 focus:ring-red-500"
                      onClick={async () => {
                        await removeIndustryCodeMutation.mutateAsync({
                          industryCode: code,
                        });
                      }}
                    >
                      {i18n._('Remove')}
                    </Button>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className="flex flex-col justify-between w-full md:flex-row">
            <div className="flex mb-2 sm:w-auto">
              <Button
                type="button"
                variant="primary"
                size="lg"
                className="w-fit max-sm:w-full"
                onClick={openAddIndustryCodeForm}
              >
                <Trans>Add New</Trans>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <AddIndustryCode
        isOpen={isAddIndustryCodeVisible}
        onCancel={closeAddIndustryCodeForm}
        onSuccess={onAddIndustryCodeSuccess}
      />
      <CaptureAndSettlementDelay />
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Phone Number Settings</Trans>
          </Typography>
        </div>
        <PhoneNumberSettingsForm
          defaultValues={{phoneNumber: tenant.brandConfiguration.phoneNumber || ''}}
        />
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Business Address</Trans>
          </Typography>
        </div>
        <BusinessAddressForm
          defaultValues={{
            businessAddressCountry: tenant.brandConfiguration.businessAddressCountry || '',
            businessAddressLine1: tenant.brandConfiguration.businessAddressLine1 || '',
            businessAddressLine2: tenant.brandConfiguration.businessAddressLine2 || '',
            businessAddressState: tenant.brandConfiguration.businessAddressState || '',
            businessAddressCity: tenant.brandConfiguration.businessAddressCity || '',
            businessAddressPostalCode: tenant.brandConfiguration.businessAddressPostalCode || '',
          }}
        />
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Email Settings</Trans>
          </Typography>
        </div>
        <EmailSettingsForm
          defaultValues={{
            supportEmail: tenant.brandConfiguration.supportEmail ?? '',
            fromEmail: tenant.brandConfiguration.fromEmail ?? '',
          }}
        />
      </div>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Terms and conditions HTML</Trans>
          </Typography>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <TermsAndConditionsForm />
        </div>
      </div>
    </div>
  );
};
