import {useLingui} from '@lingui/react';
import {MerchantAccountPublicStatus, StorePublicStatus} from '@zentact/common';
import type {MerchantAccountStatus, StoreStatus} from '@zentact/db';
import {useEffect, useMemo} from 'react';
import {InputSearchSelectFilter} from '../';

const ALL = 'all';
type StoresPickerFilterOption = {
  id: string;
  displayName: string;
  status: StoreStatus | StorePublicStatus;
  merchantAccount: {
    status: MerchantAccountPublicStatus | MerchantAccountStatus;
    onboardedAt: string | null;
  };
};

type Props = {
  selectedStore?: string | null;
  storesOptions: StoresPickerFilterOption[];
  onSelect: (storeId?: string) => void;
  allLabel?: string;
  // Optional name for form submission
  name?: string;
  contentClass?: string;
};

export const StoresPickerFilter = ({
  selectedStore,
  storesOptions,
  onSelect,
  allLabel,
  contentClass,
  name,
}: Props) => {
  const {i18n} = useLingui();

  const filteredOptions = useMemo(() => {
    const {activeOptions, inactiveOptions} = storesOptions
      .filter(option => option.merchantAccount.onboardedAt !== null)
      .reduce<{
        activeOptions: StoresPickerFilterOption[];
        inactiveOptions: StoresPickerFilterOption[];
      }>(
        (acc, option) => {
          if (option.status === 'ACTIVE' && option.merchantAccount.status === 'ACTIVE') {
            acc.activeOptions.push(option);
          } else {
            acc.inactiveOptions.push(option);
          }
          return acc;
        },
        {activeOptions: [], inactiveOptions: []}
      );
    return [...activeOptions, ...inactiveOptions];
  }, [storesOptions]);

  useEffect(() => {
    if (filteredOptions.length === 1 && filteredOptions[0]) {
      onSelect(filteredOptions[0].id);
    }
  }, [onSelect]);

  const options = useMemo(
    () => [
      ...(allLabel && filteredOptions.length > 1
        ? [
            {
              id: ALL,
              label: i18n._('All Stores'),
            },
          ]
        : []),
      ...filteredOptions.map(({id, displayName, status, merchantAccount}) => ({
        id,
        label: displayName,
        ...((status !== 'ACTIVE' || merchantAccount.status !== 'ACTIVE') && {
          className: 'text-gray-400',
          classNameActive: 'text-gray-400',
          tooltipText: i18n._('Merchant or store is not active'),
        }),
      })),
    ],
    [filteredOptions, allLabel]
  );

  return (
    <InputSearchSelectFilter
      value={selectedStore ?? (allLabel ? ALL : undefined)}
      name={name}
      options={options}
      label=""
      className="w-full mt-0"
      contentClass={contentClass}
      labelClassName="gap-0"
      onChange={storeId => onSelect(storeId === ALL ? undefined : (storeId as string))}
      placeholder={allLabel}
      defaultValue={allLabel ? ALL : undefined}
    />
  );
};
