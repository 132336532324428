import {useLingui} from '@lingui/react';
import {CurrencyCode} from '@zentact/common';
import {ChartDataWithSettingsType} from '@zentact/common';
import ReactApexChart from 'react-apexcharts';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../forms';
import {Typography} from '../typography';

export type TransactionsChartPropsType = {
  chartData?: ChartDataWithSettingsType;
  isLoading?: boolean;
  currency?: CurrencyCode;
  volumeComparisonRoute?: string;
};

export const TransactionsChart = ({
  chartData,
  currency,
  volumeComparisonRoute,
}: TransactionsChartPropsType) => {
  const {type = 'area', height = 350} = (chartData?.options?.chart as ApexChart) || {};
  const {i18n} = useLingui();
  const navigate = useNavigate();

  return (
    <div className="mt-5 overflow-hidden bg-white rounded-lg shadow">
      <div className="py-5 pl-4 pr-2 sm:pl-6 sm:pr-2">
        <div className="flex items-center justify-between pr-4 mb-5 sm:pr-6">
          <Typography variant="header-section">{i18n._('Volume of Transactions')}</Typography>
          {!!volumeComparisonRoute && (
            <Button
              type="button"
              variant="primary"
              size="md"
              className="w-fit max-md:hidden"
              onClick={() => navigate(volumeComparisonRoute)}
            >
              {i18n._('View Comparables')}
            </Button>
          )}
        </div>
        <ReactApexChart {...chartData} type={type} height={Number(height) + 30} key={currency} />
      </div>
    </div>
  );
};
