import {Loader} from '@googlemaps/js-api-loader';
import {useCallback, useMemo, useRef, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {nzStateAbbreviations} from '../constants';

export type AddressLookupItem = {
  id: string;
  name: string;
  city?: string;
  street?: string;
  houseNumberOrName?: string;
  postalCode?: string;
  stateOrProvince?: string;
  country?: string;
};

export type AddressPrediction = {
  id: string; // place_id from Google
  description: string; // The display text from prediction
};

type UseAddressAutocompleteProps = {
  countryRestrictions?: string[] | readonly string[];
  googleMapsApiKey: string;
  debounceTime?: number;
};

export const useAddressAutocomplete = ({
  countryRestrictions,
  googleMapsApiKey,
  debounceTime = 300, // Default 300ms debounce
}: UseAddressAutocompleteProps) => {
  const [isInitialized, setInitialized] = useState(false);
  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken | undefined>();

  const googleMapsApiLoader: Loader = useMemo(
    () =>
      new Loader({
        apiKey: googleMapsApiKey,
        libraries: ['places'],
      }),
    [googleMapsApiKey]
  );
  const googleMapsServices = useMemo(() => {
    if (!isInitialized) {
      return {};
    }

    return {
      placesService: new google.maps.places.PlacesService(document.createElement('div')),
      autocompleteService: new google.maps.places.AutocompleteService(),
    };
  }, [isInitialized]);

  // Create a debounced function to fetch predictions
  const debouncedFetchPredictions = useDebouncedCallback(
    async (
      input: string,
      autocompleteService: google.maps.places.AutocompleteService,
      resolve: (predictions: AddressPrediction[]) => void
    ) => {
      const {predictions} = await autocompleteService.getPlacePredictions({
        input,
        sessionToken: sessionToken.current,
        ...(countryRestrictions && {
          componentRestrictions: {country: [...countryRestrictions]},
        }),
      });

      // Return lightweight objects
      resolve(
        predictions.map(p => ({
          id: p.place_id,
          description: p.description,
        }))
      );
    },
    debounceTime
  );

  // Get lightweight predictions without fetching full details
  const getAddressPredictions = useCallback(
    (input: string): Promise<AddressPrediction[]> => {
      return new Promise(resolve => {
        if (!('autocompleteService' in googleMapsServices)) {
          throw new Error('Google maps api is not loaded');
        }

        // biome-ignore lint/style/noNonNullAssertion: TODO
        debouncedFetchPredictions(input, googleMapsServices.autocompleteService!, resolve);
      });
    },
    [googleMapsServices.autocompleteService, debouncedFetchPredictions]
  );

  // Only fetch full details when a place is selected
  const getAddressDetails = useCallback(
    (placeId: string): Promise<AddressLookupItem> => {
      if (!('placesService' in googleMapsServices)) {
        throw new Error('Google maps api is not loaded');
      }
      const {placesService} = googleMapsServices;

      return new Promise<AddressLookupItem>((resolve, reject) => {
        // biome-ignore lint/style/noNonNullAssertion: TODO
        placesService!.getDetails(
          {
            placeId: placeId,
            fields: ['address_components', 'formatted_address'],
            sessionToken: sessionToken.current,
          },
          res => {
            if (!res) {
              return reject();
            }
            // biome-ignore lint/style/noNonNullAssertion: TODO
            const addressComponentsMap = res.address_components!.reduce((acc, item) => {
              for (const type of item.types) {
                acc.set(type, item);
              }
              return acc;
              // biome-ignore lint/style/useNamingConvention: Google maps convention
            }, new Map<string, {long_name: string; short_name: string}>());

            const joinAddressParts = (...parts: Array<string | undefined>) => {
              const joined = parts.filter(v => !!v).join(' ');
              return joined.length ? joined : undefined;
            };

            // Used in US
            const locality = addressComponentsMap.get('locality');
            // Used in UK
            const postalTown = addressComponentsMap.get('postal_town');
            const administrativeAreaLevel1 = addressComponentsMap.get(
              'administrative_area_level_1'
            );
            // Germany might use sublocality_level_1 instead of locality
            const subLocalityLevel1 = addressComponentsMap.get('sublocality_level_1');

            resolve({
              // biome-ignore lint/style/noNonNullAssertion: TODO
              id: res.formatted_address!,
              // biome-ignore lint/style/noNonNullAssertion: TODO
              name: res.formatted_address!,
              city: locality
                ? locality.long_name
                : postalTown
                  ? postalTown.long_name
                  : administrativeAreaLevel1
                    ? administrativeAreaLevel1.long_name
                    : subLocalityLevel1?.long_name,
              street: joinAddressParts(
                addressComponentsMap.get('street_number')?.long_name,
                addressComponentsMap.get('route')?.long_name
              ),
              houseNumberOrName: addressComponentsMap.get('subpremise')?.long_name,
              postalCode: addressComponentsMap.get('postal_code')?.long_name,
              stateOrProvince: mapState(
                addressComponentsMap.get('administrative_area_level_1')?.short_name,
                addressComponentsMap.get('country')?.short_name
              ),
              country: addressComponentsMap.get('country')?.short_name,
            });
          }
        );
      });
    },
    [googleMapsServices.placesService]
  );

  // The useDebouncedCallback hook handles cleanup internally,
  // so we don't need to manually clear timeouts anymore

  return {
    initialize: async () => {
      await googleMapsApiLoader.importLibrary('places');
      sessionToken.current = new google.maps.places.AutocompleteSessionToken();
      setInitialized(true);
    },
    getAddressPredictions,
    getAddressDetails,
  };
};

const mapState = (stateOrProvince?: string, country?: string) => {
  if (!stateOrProvince || !country || country === 'GB') {
    return '';
  }
  if (country === 'NZ') {
    return nzStateAbbreviations[stateOrProvince];
  }
  return stateOrProvince;
};
