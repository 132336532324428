import {Switch} from '@headlessui/react';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import {ServerRouter} from '@zentact/api';
import {useState} from 'react';
import {useNotification} from '../../hooks';
import {IconSpinner} from '../../icons/spinner';
import {cn} from '../../utils';

type Props = {
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  enableRefundsInAdmin: boolean;
};

export const EnableTenantRefundsToggle = ({trpc, enableRefundsInAdmin}: Props) => {
  const i18n = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const [isLoading, setLoading] = useState(false);
  const trpcContext = trpc.useUtils();

  const updateFeatureConfiguration = trpc.tenant.updateFeatureConfiguration.useMutation({
    onSuccess: updatedTenant => {
      setLoading(false);
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
      showSuccessNotification(
        i18n._('Tenant refunds feature {status}', {
          status: updatedTenant.features?.enableRefundsInAdmin ? 'enabled' : 'disabled',
        })
      );
    },
    onError: (_error, variables) => {
      setLoading(false);
      showErrorNotification(
        i18n._('Failed to {status} tenant refunds', {
          status: variables.enableRefundsInAdmin ? 'enable' : 'disable',
        })
      );
    },
  });

  const onToggleChange = async (enableRefundsInAdmin: boolean) => {
    setLoading(true);
    updateFeatureConfiguration.mutate({enableRefundsInAdmin});
  };

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enableRefundsInAdmin}
        onChange={onToggleChange}
        className={cn(
          enableRefundsInAdmin || isLoading ? 'bg-primary-500' : 'bg-gray-200',
          'focus:ring-primary-500 relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
        )}
        disabled={isLoading}
      >
        {isLoading ? (
          <div className={cn('flex items-center justify-center', enableRefundsInAdmin && 'pl-5')}>
            <IconSpinner />
          </div>
        ) : (
          <span
            aria-hidden="true"
            className={cn(
              enableRefundsInAdmin ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        )}
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">{i18n._('Refunds')}</span>
      </Switch.Label>
    </Switch.Group>
  );
};
