import {PaymentVolumeByDatesOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {ChartDataWithSettingsType, CurrencyCode, LocaleCode, formatAmount} from '@zentact/common';

type InitChartDataType = {
  title: string;
  data?: PaymentVolumeByDatesOutput;
  primaryColor: string;
  dateFormat?: string;
  currency: CurrencyCode;
  locale: LocaleCode;
};

export const initDashboardChartData = ({
  title,
  data,
  primaryColor,
  dateFormat = 'MMM dd',
  currency,
  locale,
}: InitChartDataType): ChartDataWithSettingsType => {
  const dataY = data?.rows.map(({volume}) => volume) || [];
  const dataX = data?.rows.map(({date}) => date) || [];

  return {
    series: [
      {
        name: title,
        data: dataY,
      },
    ],
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: v => formatAmount(v, locale, currency, 0),
        },
      },
      colors: [primaryColor],
      dataLabels: {
        enabled: false,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.2,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: dataX,
        position: 'bottom',
        type: 'datetime',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
        labels: {
          datetimeUTC: true,
          format: dateFormat,
          showDuplicates: false,
        },
      },
      grid: {
        show: true,
        borderColor: '#e2e8f0',
        strokeDashArray: 3,
        padding: {
          right: 24,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: v => {
            // when the chart has small/zero values on X axis the lib can try to pass decimal values to Y axis.
            // math round is here to prevent this behaviour
            const value = Math.round(v);
            return formatAmount(value, locale, currency, 0);
          },
        },
      },
    },
  };
};
